import { NavLink } from "react-router-dom";
import { useState } from "react";
import "./Nav.css";
import { UserInfo } from "./UserInfo";
const Nav = ({ setSearchVisibility }: { setSearchVisibility?: any }) => {
  const [isOpen, setIsOpen] = useState(false);

  // close nav on clicking nav link

  const closeNav = () => {
    setIsOpen(false);
  };

  return (
    <nav className={isOpen ? "open" : ""}>
      <UserInfo />

      <ul>
        <li>
          <NavLink onClick={closeNav} to="/">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink onClick={closeNav} to="/list">
            Cards
          </NavLink>
        </li>
      </ul>

      {/* <div
        className="toggle"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <img src="/burger.svg" width="22" alt="" />
      </div> */}
      <div
        className="search-toggle"
        onClick={() => {
          setSearchVisibility(true);
        }}
      >
        <img src="/magnifying-glass.svg" width="22" alt="" />
      </div>
    </nav>
  );
};

export default Nav;
