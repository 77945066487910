import { NavLink } from "react-router-dom";
import { styled } from "styled-components";

const LogoContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 30;
`;
const Logo = () => {
  return (
    <LogoContainer>
      <NavLink to="/">
        <img src="/chunki-logo.svg" width="120" alt="chunki logo" />
      </NavLink>
    </LogoContainer>
  );
};

export default Logo;
