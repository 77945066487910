import React from "react";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

export const User = () => {
  return (
    <div>
      <h2>User</h2>
      <div>
        <NavLink to="/lists">
          <FeatherIcon icon="arrow-left-circle" />
        </NavLink>

        <NavLink to="/logout">Logout</NavLink>
      </div>
    </div>
  );
};
