import { createContext, useContext, useReducer } from "react";

interface AnimationContextInterface {
  animations: any[];
}

// need to define types for all actions and then create an array out of this and feed that into the Dispatch type
interface Action {
  type: string;
  data?: any;
  error?: any;
  id?: string;
  text?: string;
}

export const AnimationContext = createContext<AnimationContextInterface>({
  animations: [],
});
export const AnimationDispatchContext =
  createContext<React.Dispatch<Action> | null>(null);

export function AnimationsProvider({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) {
  const [animations, dispatch] = useReducer(animationsReducer, []);

  return (
    <AnimationContext.Provider value={{ animations }}>
      <AnimationDispatchContext.Provider value={dispatch}>
        {children}
      </AnimationDispatchContext.Provider>
    </AnimationContext.Provider>
  );
}

export function useAnimations() {
  return useContext(AnimationContext);
}

export function useAnimationsDispatch() {
  return useContext(AnimationDispatchContext);
}

export const animationsReducer = (animations: any[], action: any) => {
  switch (action.type) {
    case "TASK_DONE": {
      const newState = [
        ...animations,
        {
          id: action.id,
          text: action.text,
          parentId: action.parentId,
          status: action.status,
          orderNumber: action.orderNumber,
          children: [],
        },
      ];
      return newState;
    }

    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};
