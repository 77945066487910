import React, { useState } from "react";

const Stats = () => {
  return (
    <div>
      <h1>Stats</h1>
      <section>
        <p>aka the hall of f(l)ame, your biggest accomplishment.</p>
        <p>
          here you can see the massive amount of work you’ve done ever since you
          started using chunki.
        </p>
        <p>share your milestones</p>
        <p>we can also show at what time of day you are most 'productive'</p>
        <p>or when you are most likely to refine/add new tasks</p>
        <p>send notifications/reminders of tasks that are eager to be done!</p>
        <p>
          create a fun/lovable character, called chunki, that is your personal
          assistant in getting things done.
        </p>
        <p>
          you can enable screensaver mode, where chunki physically inspects the
          cards, by using an elevator to get on site and inspect all sides of
          the tasks and ask those tough questions.
        </p>
        <p>
          chunki provides the perfect balance between hyperfocused flow mode and
          full-on celebration
        </p>
      </section>
    </div>
  );
};

export default Stats;
