import React from "react";
import { styled } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const TaskLine = styled.div`
  cursor: pointer;
  width: 100px;
  height: 2px;
  border-top: 3px solid rgb(0, 255, 151);
  /* background-color: rgb(0, 255, 151); */
  padding-bottom: 1px;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-top: 3px solid rgb(255, 0, 151);
  }
`;

export const TaskStack = ({ amount }: { amount: number }) => {
  return (
    <Container>
      <div>
        {[...Array(amount).keys()].map((num) => (
          <TaskLine
            key={num}
            style={{ transform: `rotate(${-1 + Math.random() * 2}deg)` }}
          />
        ))}
      </div>
    </Container>
  );
};
