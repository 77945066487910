import React, { useContext, useState } from "react";
import { styled } from "styled-components";
import { useCardsDispatch } from "../context/CardsContext";

import { cardServiceAPI, getNextOrderNumber } from "../services/cardService";
import useAuthAxios from "../hooks/useAuthAxios";
import FeatherIcon from "feather-icons-react";
import { ModalContext } from "../providers/ModalProvider";
const Options = styled.div`
  position: absolute;
  bottom: 0.7rem;
  left: 0.7rem;
  z-index: 20;
  display: flex;
  font-size: 0.7rem;
  &:hover {
    /* opacity: 1 !important; */
  }
`;

const Button = styled.div`
  width: 1.4rem;
  height: 0.6rem;
  background: white;
  border-radius: 18px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); */
  margin-right: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border: 1px solid rgb(210, 210, 210);
  &:active {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  }
  span {
    height: 0.4rem;
  }
  cursor: pointer;
`;

const Menu = styled.div`
  position: absolute;
  /* bottom: 0.7rem;*/
  top: -1rem;
  left: 2.2rem;
  z-index: 20;
  display: flex;
  font-size: 0.7rem;
  width: 150px;
  border: 1px solid rgb(210, 210, 210);
  background: white;
  opacity: 1 !important;
  border-radius: 3px;
  box-shadow: 0px 0px 12px rgb(0, 0, 0, 0.2);

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 500;

    li {
      padding: 0.5rem;
      margin: 0.3rem;
      padding-bottom: 0.5rem;
      transition: 0.2s all;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        transition: 0.2s all;
        background: rgba(210, 210, 210, 0.3);
      }
      svg {
        width: 15px;
        height: 15px;
        margin-right: 0.6rem;
        margin-left: 0.1rem;
      }
    }
  }
`;

export const CardOptions = ({
  id,
  features,
  setIsEditing,
  setActive,
  isEditing,
}: {
  id: string;
  features: string[];
  setIsEditing: any;
  setActive: any;
  isEditing: boolean;
}) => {
  const authAxios = useAuthAxios();
  const { deleteCard } = cardServiceAPI(authAxios);

  const [menuVisible, setMenuVisible] = useState(false);
  const dispatch = useCardsDispatch();

  const { setModal } = useContext(ModalContext);

  const toggleMenu = (e: any) => {
    setMenuVisible(!menuVisible);
  };

  const actionHandler = (e: any, type: string) => {
    if (dispatch) {
      if (type === "delete") {
        dispatch({
          type: "DELETE_CARD",
          data: { id },
        });
        deleteCard(id);
      }
      if (type === "rename") {
        setIsEditing(true);
      }
      if (type === "focus") {
        setActive(id);
      }
      if (type === "move") {
        setModal({ type: "move-card", cardId: id });
      }
      if (type === "pin") {
        // ...
      }
    }
  };

  return (
    <Options className="card-options" onClick={toggleMenu}>
      <Button>
        <span>...</span>
      </Button>
      {menuVisible && (
        <Menu
          onMouseLeave={() => {
            setMenuVisible(false);
          }}
        >
          <ul>
            {features.includes("focus") && (
              <li onClick={(e) => actionHandler(e, "focus")}>
                <FeatherIcon icon="disc" /> Focus
              </li>
            )}

            {features.includes("rename") && (
              <li onClick={(e) => actionHandler(e, "rename")}>
                <FeatherIcon icon="edit-2" /> Rename
              </li>
            )}

            {features.includes("delete") && (
              <li onClick={(e) => actionHandler(e, "delete")}>
                <FeatherIcon icon="delete" /> Delete
              </li>
            )}

            {features.includes("move") && (
              <li onClick={(e) => actionHandler(e, "move")}>
                <FeatherIcon icon="corner-right-up" /> Move to
              </li>
            )}

            {features.includes("pin") && (
              <li onClick={(e) => actionHandler(e, "pin")}>
                <FeatherIcon icon="star" /> Pin this card
              </li>
            )}

            {/* <li onClick={}>Move to</li> */}
          </ul>
        </Menu>
      )}
    </Options>
  );
};
