import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useLocation, Navigate } from "react-router-dom";

export const ProtectedRoutes = () => {
  const { auth } = useAuth();
  const location = useLocation();

  // return auth?.accessToken ? (
  //   <Outlet />
  // ) : (
  //   <Navigate to="/login" state={{ from: location }} replace />
  // );
  return <Outlet />;
};

export default ProtectedRoutes;
