import { AxiosInstance } from "axios";
import * as R from "ramda";
import { byOrdernumber } from "../util/sort";

type Card = {
  id: string;
  parentId: string;
  text: string;
  hide_children?: boolean;
  readiness?: string;
  status: string;
  orderNumber: number;
};

const cardServiceAPI = (axios: AxiosInstance) => {
  const addCard = async (card: Card) => {
    try {
      const result = await axios.post(
        `https://jr6pjsudef.execute-api.eu-central-1.amazonaws.com/test/api/tasks/`,
        {
          id: card.id,
          parentId: card.parentId,
          text: card.text,
          status: "open",
          orderNumber: card.orderNumber,
          readiness: null,
        }
      );
      console.log("result", result);
    } catch (err) {
      console.log(err); // need to display a user friendly error message in the UI
    }
  };

  const batchUpdateCards = async (ids: string[], props: any) => {
    try {
      await axios.put(
        `https://jr6pjsudef.execute-api.eu-central-1.amazonaws.com/test/api/tasks/batch/`,
        {
          ids,
          ...props,
        }
      );
    } catch (err) {
      console.log(err); // need to display a user friendly error message in the UI
    }
  };

  const updateCard = async (card: any) => {
    try {
      await axios.put(
        `https://jr6pjsudef.execute-api.eu-central-1.amazonaws.com/test/api/tasks/${card.id}/`,
        {
          ...card,
        }
      );
    } catch (err) {
      console.log(err); // need to display a user friendly error message in the UI
    }
  };

  const deleteCard = async (id: any) => {
    try {
      await axios.delete(
        `https://jr6pjsudef.execute-api.eu-central-1.amazonaws.com/test/api/tasks/${id}/`
      );
    } catch (err) {
      console.log(err); // need to display a user friendly error message in the UI
    }
  };

  return {
    addCard,
    batchUpdateCards,
    deleteCard,
    updateCard,
  };
};

const getCard = (cardList: any, id: string): Card => {
  return cardList.find((card: any) => {
    return card.id.toString() === id;
  });
};

const getCardParents = (
  cardList: any,
  id: string,
  parents: any[] = []
): any[] => {
  // get the card
  const card = getCard(cardList, id.toString());
  const parentId = card?.parentId;

  // "0" being the `virtual` parent for all cards in / root.
  if (!parentId) {
    return [];
  } else if (parentId === "0") {
    return parents;
  }

  // fetch the parent card.
  const parentCard = getCard(cardList, parentId.toString());
  const allParents = [...parents, parentCard];

  if (parentCard?.parentId) {
    return getCardParents(cardList, parentId, allParents);
  } else {
    return allParents;
  }
};

const hasChildren = (cardList: any, id: string): any[] => {
  return cardList.find(
    (card: any) => card.parentId === id && card.status === "open"
  );
};

const getCardChildren = (cardList: Card[], id: string) => {
  const childrenCards = cardList.filter((card: any) => {
    return card.parentId === id && card.status === "open";
  });
  return R.sort(byOrdernumber, childrenCards);
};

const getNextOrderNumber = (cardList: Card[], parentId: string) => {
  const siblings = getCardChildren(cardList, parentId);
  return Math.max(...siblings.map((card: any) => card.orderNumber)) + 1;
};

const getParentCard = (cardList: any, id: string): Card => {
  return cardList.find((card: any) => {
    return card.id === id;
  });
};

const moveCard = (itemId: string, position: number) => {
  // need to change position within its list, which is, the list of cards that belong to a specific parent
  // would be convenient to _just_ change the sort idx of the card, and have that trigget the re-render.
  // let’s try!
  // find the card first
  // then change its index property (need to add this though)
  // and we should sort the cards by their index before rendering.
};

export {
  cardServiceAPI,
  getCard,
  getCardParents,
  getCardChildren,
  getNextOrderNumber,
  getParentCard,
  hasChildren,
  moveCard,
};
