import React from "react";

export const LetsGoArrow = ({
  width = 180,
  name = "basic-arrow",
  fillColor,
}: {
  width: number;
  name: string;
  fillColor: string;
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 163.3 164.6"
      enableBackground="new 0 0 163.3 164.6"
      xmlSpace="preserve"
      width={width}
      className="lets-go-arrow"
    >
      <style type="text/css">
        {`
	    .lets-go-arrow .st0{fill:#F4F4F6;}   
      .lets-go-arrow path {
            transition: 0.2s all;
            fill: ${fillColor};
        }     
        `}
      </style>
      <rect className="st0" width="163.3" height="164.6" />
      <path
        d="M35.6,96.5c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0l6.1,3.4l0,0
	l6,3.5l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l6,2.5c0,0,0,0,0.1,0c0,0,0,0,0,0l6.6,1.3c0,0,0.1,0,0.1,0l0,0h7.1l0,0
	c0,0,0.1,0,0.1,0l7.4-1.4c0,0,0,0,0,0c0,0,0,0,0,0l7.8-2.6c0,0,0,0,0,0c0,0,0,0,0,0l7.9-4c0,0,0,0,0,0c0,0,0,0,0,0l11.8-6.8
	l11.9,6.8c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0v0l12.1-27.8c0,0,0,0,0,0c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0-0.1c0,0,0,0,0,0v-14c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0
	c0,0,0,0,0,0s0,0,0,0l-48.3,7.1c0,0,0,0,0,0c0,0,0,0-0.1,0s0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0V78l-6,3l-6.1,2.2l-6,1.1l-5.6,0.1l-5.1-1.1l-4.7-2
	c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0
	c0,0,0,0-0.1,0.1c0,0,0,0,0,0l0,0h0c0,0,0,0,0,0l-4.3,7.3c0,0,0,0,0,0c0,0,0,0,0,0l-4.3,7.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1
	C35.6,96.4,35.6,96.4,35.6,96.5C35.6,96.5,35.6,96.5,35.6,96.5z M40.8,89.3C40.8,89.3,40.8,89.3,40.8,89.3l4.1-6.9l5.6,3.2l0,0
	l5.6,3.3l-4,6.9c0,0,0,0,0,0l-4.1,7l-5.5-3.3c0,0,0,0,0,0c0,0,0,0,0,0l-5.6-3.2L40.8,89.3z M126.7,71.3l-11.1,25.5V85.4l11.1-25.6
	L126.7,71.3L126.7,71.3z M90.7,98.8l-7.8,4l-7.7,2.6l-7.4,1.4h-7l-6.5-1.2l-5.4-2.3l4.1-6.9l0,0l0,0l4-7l4.3,1.8c0,0,0,0,0,0
	c0,0,0,0,0,0l5.3,1.1c0,0,0.1,0,0.1,0l0,0h5.6l0,0c0,0,0.1,0,0.1,0l6.1-1.3c0,0,0,0,0,0c0,0,0,0,0,0l6.2-2.1c0,0,0,0,0,0
	c0,0,0,0,0,0l6.3-3.2c0,0,0,0,0,0c0,0,0,0,0,0l11.3-6.5V92L90.7,98.8z M103.5,79.2l11.1,6.4v12.8L103.5,92V79.2z M89.9,71.4l-10.6,6
	V65.3L89.9,71.4z"
      />
    </svg>
  );
};
