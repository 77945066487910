import React, { useEffect } from "react";
import useAuthAxios from "../hooks/useAuthAxios";

import List from "../components/List/List";
import Logo from "../components/Logo";

import { useCardsDispatch } from "../context/CardsContext";
import { useCards } from "../context/CardsContext";
import { useParams } from "react-router";

import { QuickNav } from "../components/QuickNav";
import Nav from "../components/Nav";
import { ScrollToTop } from "../hooks/scrollToTop";
import { BigCheck } from "../components/BigCheck";
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
import { getCard, getCardChildren } from "../services/cardService";

const PublickBacklogLink = styled.div`
  position: fixed;
  bottom: 2rem;
  width: 10vw;
  text-align: center;
  left: 45vw;
  a {
    color: black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

// TODO: make this generic to filter for any possible readiness status
const filterCards = (filter: string, cards: any) => {
  if (filter === "ready-to-go") {
    const filteredCards = cards.filter(
      (card: any) => card.readiness === "LETS_GO" && card.status === "open"
    );
    // console.log({ filteredCards });
    return filteredCards;
  }
  return cards;
};

const getListType = (listId: string) => {
  if (!listId) {
    return "root";
  }
  if (listId === "ready-to-go") {
    return "ready-to-go";
  }
  if (listId.length === 36) {
    return "with-parent";
  }
};

const getListDetails = (
  cards: any,
  listType: string = "",
  listId: string = ""
) => {
  let card, listCards, listTitle;
  let parentTitle = "Root";

  if (cards.length > 0) {
    if (listType === "ready-to-go") {
      listTitle = "Ready to go 🚀";
      listCards = filterCards("ready-to-go", cards);
    } else if (listType === "root") {
      listTitle = "Root";
      listCards = getCardChildren(cards, "0");
    } else if (listType === "with-parent") {
      card = getCard(cards, listId);
      const parentCard = getCard(cards, card.parentId);
      listTitle = card.text;
      listCards = getCardChildren(cards, listId);
      parentTitle = parentCard?.text || "Root";
    }
  } else {
    listCards = [];
  }
  return [card, listTitle, listCards, parentTitle];
};

const ListContainer = ({
  filter,
  focusMode,
  setFocusMode,
  searchVisible,
  setSearchVisible,
}: {
  filter?: string;
  focusMode: any;
  setFocusMode: any;
  searchVisible?: any;
  setSearchVisible?: any;
}) => {
  const dispatch = useCardsDispatch();
  const { cards } = useCards();
  const authAxios = useAuthAxios();
  const params = useParams();

  const listType = getListType(params.listId ?? "");
  const [card, listTitle, listCards, parentTitle] = getListDetails(
    cards,
    listType,
    params.listId
  );

  useEffect(() => {
    const fetchCards = async () => {
      if (dispatch) {
        try {
          const result = await authAxios.get(
            "https://jr6pjsudef.execute-api.eu-central-1.amazonaws.com/test/api/tasks/"
          );
          dispatch({
            type: "FETCHING_CARDS_SUCCESS",
            data: result.data,
          });
        } catch (err) {
          dispatch({
            type: "FETCHING_CARDS_ERROR",
            error: err,
          });
        }
      }
    };
    fetchCards();
  }, [dispatch, authAxios]);

  return (
    <div>
      <ScrollToTop />
      <BigCheck />

      <PublickBacklogLink>
        <NavLink to="/public-backlog">Feature Backlog</NavLink>
      </PublickBacklogLink>

      <Logo />
      <Nav setSearchVisibility={() => setSearchVisible(!searchVisible)} />
      <>
        {searchVisible && (
          <QuickNav data={cards} setSearchVisible={setSearchVisible} />
        )}
      </>
      <List
        title={listTitle}
        parentTitle={parentTitle}
        card={card}
        allCards={cards}
        listCards={listCards}
        setFocusMode={setFocusMode}
        focusMode={focusMode}
      />
    </div>
  );
};

export default ListContainer;
