import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { GlobalHotKeys } from "react-hotkeys";

import "./App.css";
import ListContainer from "./pages/ListContainer";
import Stats from "./pages/Stats";
import Logo from "./components/Logo";
import Hello from "./pages/Hello";
import { User } from "./pages/User";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import ProtectedRoutes from "./components/ProtectedRoutes";
import { ProcessMagicLogin } from "./components/ProcessMagicLogin";
import { PublicBacklog } from "./pages/PublicBacklog";
// import useAuth from "./hooks/useAuth";

const App = () => {
  const [focusMode, setFocusMode] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const navigate = useNavigate();

  // whenever the app renders, initialise our auth state based on the tokens in localStorage
  useEffect(() => {
    // if we have nothing on you in localStorage, we’ll forward to /login
    // if we do, could even _try_ and refresh our token, why not?
    // if that fails --> /login
    // console.log("we’re here", localStorage.getItem("autho"));
    // navigate("/login");
  }, [navigate]);

  const launchQuickNav = (event: any) => {
    event.preventDefault();
    setSearchVisible(!searchVisible);
  };

  const keyMap = {
    QUICK_NAV: "command+p",
  };

  const handlers = {
    QUICK_NAV: launchQuickNav,
  };

  return (
    <div className={`app ${focusMode ? "focus" : ""}`}>
      <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route
            path="/lists"
            element={
              <ListContainer
                setFocusMode={setFocusMode}
                focusMode={focusMode}
                searchVisible={searchVisible}
                setSearchVisible={setSearchVisible}
              />
            }
          />
          <Route
            path="/lists/:listId"
            element={
              <ListContainer
                setFocusMode={setFocusMode}
                focusMode={focusMode}
                searchVisible={searchVisible}
                setSearchVisible={setSearchVisible}
              />
            }
          />
          <Route
            path="/lists/pinned"
            element={
              <ListContainer
                setFocusMode={setFocusMode}
                focusMode={focusMode}
                searchVisible={searchVisible}
                setSearchVisible={setSearchVisible}
              />
            }
          />
          <Route path="/user" element={<User />} />
          <Route path="/feel-good" element={<Stats />} />
          <Route path="/public-backlog" element={<PublicBacklog />} />
        </Route>

        <Route path="/" element={<Hello />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/magic/" element={<ProcessMagicLogin />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </div>
  );
};

export default App;
