import { createContext, useContext, useReducer } from "react";
import { getCardChildren } from "../services/cardService";
import * as R from "ramda";

interface CardsContextInterface {
  cards: any[];
}

// need to define types for all actions and then create an array out of this and feed that into the Dispatch type
interface Action {
  type: string;
  data?: any;
  error?: any;
  id?: string;
  text?: string;
  parentId?: string;
  readiness?: string;
  orderNumber?: number;
}

export const CardsContext = createContext<CardsContextInterface>({ cards: [] });
export const CardsDispatchContext =
  createContext<React.Dispatch<Action> | null>(null);

export function CardsProvider({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) {
  const [cards, dispatch] = useReducer(cardsReducer, []);

  return (
    <CardsContext.Provider value={{ cards }}>
      <CardsDispatchContext.Provider value={dispatch}>
        {children}
      </CardsDispatchContext.Provider>
    </CardsContext.Provider>
  );
}

export function useCards() {
  return useContext(CardsContext);
}

export function useCardsDispatch() {
  return useContext(CardsDispatchContext);
}

export const cardsReducer = (cards: any[], action: any) => {
  switch (action.type) {
    case "ADD_CARD": {
      const newState = [
        ...cards,
        {
          id: action.id,
          text: action.text,
          parentId: action.parentId,
          status: action.status,
          orderNumber: action.orderNumber,
          children: [],
        },
      ];

      // newState.map((card: any) => {
      //   return {
      //     ...card,
      //     children: getCardChildren(newState, card.id),
      //   };
      // });

      const withChildren = newState.map((card: any) => {
        return {
          ...card,
          children: getCardChildren(newState, card.id),
        };
      });

      return withChildren;
    }
    case "UPDATE_CARD": {
      const withAdjustedCard = cards.map((c) => {
        if (c.id === action.id) {
          return { ...c, ...action.data };
        } else {
          return c;
        }
      });

      const withChildren = withAdjustedCard.map((card: any) => {
        return {
          ...card,
          children: getCardChildren(withAdjustedCard, card.id),
        };
      });

      return withChildren;
    }
    case "UPDATE_LIST_ORDER": {
      const newPosition = action.data.newOrder;

      const byOrdernumber = R.comparator(
        (a: any, b: any) => a.orderNumber < b.orderNumber
      );

      // all cards for this list
      const allSiblings = R.sort(
        byOrdernumber,
        cards.filter(
          (c: any) => c.parentId === action.data.parentId && c.status === "open"
        )
      );

      // all cards outside of this list
      const nonSiblings = cards.filter(
        (c) => c.parentId !== action.data.parentId && c.status === "open"
      );

      // just the card that moved
      const subjectCard = allSiblings.filter((c: any) => c.id === action.id);

      // cards without the card that moved
      const withoutSubjectCard = allSiblings.filter(
        (c: any) => c.id !== action.id
      );

      // the list with the new position of the card
      const newlyOrdered = [
        ...withoutSubjectCard.slice(0, newPosition),
        ...subjectCard,
        ...withoutSubjectCard.slice(newPosition),
      ];

      // with order numbers added (ascending)
      const withNewOrderNumbers = newlyOrdered.map((c, idx) => {
        c.orderNumber = idx;
        return c;
      });

      const newCards = [...nonSiblings, ...withNewOrderNumbers];

      return newCards;
    }

    case "DELETE_CARD": {
      return cards.filter((c) => c.id !== action.data.id);
    }

    case "ARCHIVE_ALL": {
      const filteredCards = cards.filter((c) => c.status !== "done");

      const withChildren = filteredCards.map((card: any) => {
        return {
          ...card,
          children: getCardChildren(filteredCards, card.id),
        };
      });

      return withChildren;
    }

    case "deleted": {
      const filteredCards = cards.filter((t) => t.id !== action.id);

      const withChildren = filteredCards.map((card: any) => {
        return {
          ...card,
          children: getCardChildren(filteredCards, card.id),
        };
      });

      return withChildren;
    }
    case "FETCHING_CARDS_START": {
      return cards;
    }
    case "FETCHING_CARDS_SUCCESS": {
      const cards = action.data.data;
      const cardsWithChildren = cards
        .filter((card: any) => {
          return card.status === "open" || card.status === "done";
        })
        .map((card: any) => {
          return {
            ...card,
            children: getCardChildren(cards, card.id),
          };
        });
      return cardsWithChildren;
    }
    case "FETCHING_CARDS_ERROR": {
      console.log(action.error);
      return cards;
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};
