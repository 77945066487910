import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuthAxios from "../hooks/useAuthAxios";
import useAuth from "../hooks/useAuth";
const logoutURL =
  "https://jr6pjsudef.execute-api.eu-central-1.amazonaws.com/test/api/auth/logout/";

export const Logout = () => {
  const authAxios = useAuthAxios();
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  useEffect(() => {
    const logOutRequest = async () => {
      const { status } = await authAxios.post(logoutURL, {});
      if (status === 200) {
        if (setAuth) {
          setAuth({});
        }
        navigate(`/`);
      } else {
        console.log(`Unexpected status ${status}`);
      }
    };

    logOutRequest();
  });

  return <div>Logout</div>;
};
