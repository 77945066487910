import React from "react";
import { styled } from "styled-components";
import { Start } from "../components/Start";
import { devices } from "../styling-config";
import { LandingPageBackground } from "../components/Graphics/LandingPageBackground";

const BackgroundContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  svg {
    width: 1400px;
  }

  @media only screen and ${devices.xs} {
    width: 100vw;
    overflow: hidden;
    img.hash {
      display: none;
    }
  }
  @media only screen and ${devices.lg} {
    width: 1400px;
    overflow: visible;
    img.hash {
      display: block;
    }
  }
`;

const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  /* border: 1px solid rgba(255, 0, 0, 0.1); */
`;

const Heading1 = styled.h1`
  font-size: 5rem;
  margin-bottom: 3rem;
  text-align: center;
  margin-top: 0;
`;

const Heading2 = styled.h2`
  font-size: 1.2rem;
  text-align: center;
`;

const Opener = styled.div`
  width: 48%;
  /* width: 400px; */
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;

  img {
    margin-bottom: 4rem;
  }
`;

const Closer = styled.div`
  position: relative;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 1rem;

  @media only screen and ${devices.xs} {
    margin-left: 0;
  }
  @media only screen and ${devices.lg} {
    margin-left: 43%;
  }
`;

const Chunk = styled.div`
  border: 1px solid red;
  padding: 3rem;
`;

const SellyFeature = styled.div`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 8px;
  padding: 1rem;
  width: 300px;
  margin-bottom: 3rem;
  box-shadow: 2px 2px 10px rgba(0, 255, 0, 0.5);
  background: white;
  font-size: 1rem;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: auto;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.5;
  h2 {
    margin-top: 0;
    font-size: 1.4rem;
    font-weight: 400;
    color: red;
  }
  @media only screen and (max-width: 640px) {
    margin-left: auto !important;
    margin-top: auto !important;
  }
  @media only screen and ${devices.lg} {
  }
`;

const Hello = () => {
  return (
    <>
      <BackgroundContainer>
        <img
          className="hash"
          src="/hashes-1.png"
          alt=""
          width="400"
          style={{ position: "absolute", top: "417px", left: "282px" }}
        />
        <img
          className="hash"
          src="/hashes-2.png"
          alt=""
          width="276"
          style={{ position: "absolute", top: "860px", left: "812px" }}
        />
        <img
          className="hash"
          src="/hashes-3.png"
          alt=""
          width="234"
          style={{ position: "absolute", top: "1397px", left: "348px" }}
        />
        <LandingPageBackground />
        {/* <LandingGuides /> */}
      </BackgroundContainer>
      <Container>
        <Opener>
          <img
            src="/chunki-logo.svg"
            width="125"
            alt="chunki logo"
            style={{ marginTop: "0" }}
          />

          <Heading2
            style={{
              textAlign: "left",
              width: "320px",
              margin: "0 auto",
              marginBottom: "2rem",
            }}
          >
            Chunki is the task manager that helps you get things done.
          </Heading2>

          <Heading2
            style={{ textAlign: "center", width: "320px", margin: "0 auto" }}
          >
            Here’s how:
          </Heading2>
        </Opener>

        <SellyFeature style={{ marginLeft: "0" }}>
          <h2>Nested lists</h2>
          <p>
            Ever seen a Kanban board get out of control? We have! Nested lists
            fix that and allow you to have dedicated lists for each chunk of
            work.
          </p>
        </SellyFeature>

        <SellyFeature style={{ marginLeft: "30rem", marginTop: "12rem" }}>
          <h2>Tiny chunks</h2>
          <p>
            Speaking of chunks, Chunki encourages you to use tiny tasks, this
            can really help you get things done.
          </p>
        </SellyFeature>

        <SellyFeature style={{ marginLeft: "35rem", marginTop: "15rem" }}>
          <h2>Readiness Labels</h2>
          <p>
            Adding a ‘no way, don’t think so, maybe, let’s go’ label to a task
            helps you identify which tasks need to be split up further.
          </p>
        </SellyFeature>

        <SellyFeature style={{ marginLeft: "26rem", marginTop: "17rem" }}>
          <h2>Intermezzi</h2>
          <p>
            The intermezzi are short visual rewards for doing the work. They’re
            a great way to celebrate your progress and keep you going.
          </p>
        </SellyFeature>

        <Closer>
          <Start />
        </Closer>
      </Container>
    </>
  );
};

export default Hello;
