import { useState } from "react";
import NewCard from "../NewCard";
import Card from "../Card";
import "./List.css";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getCardParents, hasChildren } from "../../services/cardService";
import { Breadcrumb } from "../Breadcrumb";

import { styled } from "styled-components";
import { TasksDone } from "../TasksDone";

const Loader = styled.div`
  margin-bottom: 2rem;
`;

const FocusOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

const List = ({
  card,
  allCards,
  listCards,
  focusMode,
  setFocusMode,
  title,
  parentTitle,
}: {
  card: any;
  allCards: any;
  listCards: any;
  focusMode: any;
  setFocusMode: any;
  title: string;
  parentTitle: string;
}) => {
  const [activeCardId, setActiveCardId] = useState("");

  const setActive = (id: string) => {
    setActiveCardId(id === activeCardId ? "" : id);
    id === activeCardId ? setFocusMode(false) : setFocusMode(true);
  };

  const disableFocusMode = () => {
    setActiveCardId("");
    setFocusMode(false);
  };

  return (
    <div className={`board ${focusMode ? "focus" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{card?.text || "/"}</title>
        <link rel="canonical" href={`http://chunki.app/lists/${card?.id}`} />
      </Helmet>
      {focusMode ? (
        <FocusOverlay
          onClick={() => {
            disableFocusMode();
          }}
        ></FocusOverlay>
      ) : (
        ""
      )}
      <TasksDone />
      <header>
        {listCards && (
          <Breadcrumb
            card={card}
            parentCards={getCardParents(allCards, card?.id || "").reverse()}
          />
        )}
      </header>

      <div className={`cards ${!card?.id ? "Root" : ""}`}>
        <div className="list-title">
          {card && (
            <NavLink
              className="parent-link"
              to={
                card.parentId && card.parentId !== "0"
                  ? `/lists/${card.parentId}`
                  : `/lists`
              }
            >
              <h1>
                <span className="parent">&larr; {parentTitle}</span>
                <span className="current">{card?.text}</span>
              </h1>
            </NavLink>
          )}
          {!card && <h1>{title}</h1>}
        </div>

        {!listCards && <Loader>Loading...</Loader>}

        {listCards &&
          listCards.map((card: any, idx: number) => {
            return (
              <Card
                {...card}
                key={card.id}
                index={card.orderNumber}
                isActive={activeCardId === card.id}
                setActive={setActive}
                hasChildren={allCards && hasChildren(allCards, card.id)}
                childCount={card?.children?.length}
                features={["done", "focus", "children_link"]}
                parentId={card.parentId}
                orderNumber={card.orderNumber}
              />
            );
          })}
        {<NewCard parentCardId={card?.id || "0"} />}
      </div>
    </div>
  );
};

export default List;
