import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get(
      "https://jr6pjsudef.execute-api.eu-central-1.amazonaws.com/test/api/auth/token/refresh/",
      {
        withCredentials: true,
      }
    );
    if (setAuth) {
      setAuth((prev: any) => {
        // console.log("setAuth", prev, response.data);
        return { ...prev, accessToken: response.data.accessToken };
      });
    }

    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
