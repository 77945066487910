import "./Card.css";

import React, { useEffect, useRef, useState } from "react";
import { CardApproval } from "./CardApproval";
import { useDrag, useDrop } from "react-dnd";
import { useEditable } from "../util/useEditable";

import { ItemTypes } from "..";
import { Tags } from "./Tags";
import { NavLink } from "react-router-dom";
import { cardServiceAPI } from "../services/cardService";
import { useCardsDispatch } from "../context/CardsContext";
import useAuthAxios from "../hooks/useAuthAxios";
import { CardOptions } from "./CardOptions";
import { ConditionalNavLink } from "./ConditionalNavLink";
import { useAnimationsDispatch } from "../context/AnimationContext";

import FeatherIcon from "feather-icons-react";

export type tagMap = {
  [key: string]: string;
};

export const tagMapping: tagMap = {
  NO_WAY: "no way",
  DONT_THINK_SO: "don’t think so",
  MAYBE: "maybe",
  LETS_GO: "let’s go",
};

const Card = ({
  id,
  text,
  status,
  isActive,
  setActive,
  readiness,
  features,
  hasChildren = false,
  childCount = 0,
  isParent,
  index,
  orderNumber,
  parentId,
}: {
  id: string;
  text: string;
  status: string;
  isActive?: boolean;
  setActive?: any;
  readiness?: string;
  features?: string[];
  hasChildren?: boolean;
  childCount?: number;
  isParent?: boolean;
  index: number;
  orderNumber: number;
  parentId: string;
}) => {
  const [isFocused] = useState(false);
  const dispatch = useCardsDispatch();
  const animationDispatch = useAnimationsDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const authAxios = useAuthAxios();
  const { updateCard } = cardServiceAPI(authAxios);

  const ref = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [cardText, setCardText] = useState(text);
  const editorRef = useRef<HTMLInputElement>(null);
  const editOptions = { disabled: !isEditing };
  const Edit = useEditable(editorRef, setCardText, editOptions);

  useEffect(() => {
    if (isEditing) {
      editorRef?.current?.focus();
      const lastChar = editorRef.current?.innerText.length || 0;
      Edit.move(lastChar);
    }
  }, [Edit, editorRef, isEditing]);

  const [{ handlerId, isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        isOver: !!monitor.isOver(),
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: any, monitor) {
      console.log(`item got dropped on card ${text}`, item, monitor);
      console.log("host id", id, "cardId", item.id);
      if (item.id === id) {
        updateCard({ id, orderNumber: item.index });
      } else {
        handleDrop({ id: item.id, parentId: id });
      }
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      let hoverClientY = 0;
      if (clientOffset) {
        hoverClientY = clientOffset.y - hoverBoundingRect.top;
      }

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // updates local state
      updateCardOrderNumber(item.id, dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  const updateReadiness = (id: string) => (readiness: string) => {
    if (dispatch) {
      dispatch({
        type: "UPDATE_CARD",
        id,
        data: { readiness },
      });
    }
    updateCard({ id, readiness });
  };

  const updateCardStatus = (id: string, status: string) => {
    if (dispatch) {
      dispatch({
        type: "UPDATE_CARD",
        id,
        data: { status },
      });
      if (status === "done" && animationDispatch) {
        animationDispatch({
          type: "TASK_DONE",
        });
      }
    }
    updateCard({ id, status });
  };

  const updateCardOrderNumber = (
    id: string,
    orderNumber: number,
    hoverIndex: number
  ) => {
    if (dispatch) {
      dispatch({
        type: "UPDATE_LIST_ORDER",
        id,
        data: { cardId: id, parentId, newOrder: hoverIndex },
      });
    }
  };

  const handleDrop = async ({
    id,
    parentId,
  }: {
    id: string;
    parentId: string;
  }) => {
    if (dispatch) {
      dispatch({
        type: "UPDATE_CARD",
        id,
        data: { cardId: id, parentId },
      });
    }
    // update server state?
    await updateCard({ id, parentId });
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      e.target.blur();
    }
  };

  const handleBlur = async (e: any) => {
    if (dispatch) {
      dispatch({
        type: "UPDATE_CARD",
        id,
        data: { cardId: id, text: cardText },
      });
    }
    setIsEditing(false);
    await updateCard({ id, text: cardText });
  };

  return (
    <div
      className={`card-wrapper`}
      ref={ref}
      style={{
        opacity: isDragging ? 0.2 : 1,
        cursor: "",
      }}
    >
      <div
        className={`card ${isActive ? "active" : ""} ${
          isFocused ? "focused " : ""
        }${hasChildren ? "has-children " : ""}status-${status} ${
          isOver ? "is-over" : ""
        }`}
        tabIndex={1}
      >
        {hasChildren && !isEditing && (
          <NavLink className="full-card-link" to={`/lists/${id}/`}></NavLink>
        )}
        {!isActive && (
          <CardOptions
            id={id}
            features={[
              "rename",
              ...(!hasChildren ? ["delete", "focus"] : []),
              "move",
              "pin",
            ]}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setActive={setActive}
          />
        )}

        <div className="content">
          <div className="text" spellCheck="false">
            <ConditionalNavLink
              show={hasChildren && !isEditing}
              to={`/lists/${id}/`}
            >
              <div
                className="editable-text"
                ref={editorRef}
                onBlur={handleBlur}
                onKeyDown={handleKeyPress}
              >
                {cardText}
              </div>
            </ConditionalNavLink>
          </div>
        </div>

        {features?.find((s) => s === "done") && !hasChildren && (
          <div className="change-status">
            <div
              className="set-done"
              onClick={() => {
                updateCardStatus(id, "done");
              }}
            >
              {status !== "done" ? (
                <div>
                  {" "}
                  <img src="/check.svg" width="25" alt="" />
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              onClick={() => {
                updateCardStatus(id, "archived");
              }}
            >
              {status === "done" ? (
                <div>
                  <img src="/archive-icon.svg" width="25" alt="archive" />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}

        <div className="feature-buttons-temp">
          {features?.find((s) => s === "archive") && !hasChildren && (
            <div
              className="set-archived"
              onClick={() => {
                updateCardStatus(id, "archived");
              }}
            >
              archive
            </div>
          )}
          {!childCount && !isParent && (
            <div className="add-child">
              <div className="add-first-child">
                <NavLink to={`/lists/${id}/`}>+</NavLink>
              </div>
            </div>
          )}
        </div>

        {features?.find((s) => s === "children_link") && (
          <div className="children">
            {childCount ? (
              <div className="link-to-children">
                <div className="child-count">{childCount || ""}</div>
                <img src="/fat-arrow-next-100.svg" width="12" alt="" />
              </div>
            ) : (
              <></>
            )}
          </div>
        )}

        {isParent && <div className="up-arrow">&larr;</div>}

        <div className="footer">
          {!isParent && !hasChildren && (
            <Tags readiness={readiness} updateReadiness={updateReadiness(id)} />
          )}
        </div>
        <CardApproval />
      </div>
    </div>
  );
};

export default Card;
