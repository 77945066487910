import React from "react";

export default function CheckGraphic() {
  return (
    <div className="check">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1160.4 1160.4"
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`
	.st0{fill:#FFFFFF;}
    .st1{fill:none;stroke:#22FF00;stroke-width:165;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:5.1914;stroke-opacity:0.6;}`}
        </style>
        <circle className="st0" cx="580.2" cy="580.2" r="579.7" />
        <g transform="matrix(1,0,0,1,-377.642,-163)">
          <g transform="matrix(1,0,0,1,298.65,1.08317)">
            <path className="st1" d="M590.7,1018.1l311-554.1" />
          </g>
          <g transform="matrix(1,0,0,1,297.737,1.71181)">
            <path className="st1" d="M417.6,786.8l170.8,232.8" />
          </g>
        </g>
      </svg>
    </div>
  );
}
