import { useState } from "react";
import "./QuickNav.css";
import Fuse from "fuse.js";
// import { useNavigate } from "react-router-dom";

export const CardSearch = ({
  data,
  onSelect,
  styles,
}: {
  data: any;
  onSelect: any;
  styles: { [key: string]: string };
}) => {
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [searchData, setSearchData] = useState<any[]>([]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    const { key } = e;

    // move down
    if (key === "ArrowDown") {
      if (focusedIndex < searchData.length - 1) {
        setFocusedIndex(focusedIndex + 1);
      }
    }
    // move up
    if (key === "ArrowUp") {
      if (focusedIndex > 0) {
        setFocusedIndex(focusedIndex - 1);
      }
    }

    // hide search results
    if (key === "Escape") {
      //   setSearchVisible(false);
    }

    // select the current item
    if (key === "Enter" && searchData[focusedIndex].id) {
      //   setSearchVisible(false);
      onSelect(searchData[focusedIndex].id);
      //   navigate(`/lists/${searchData[focusedIndex].id}`);
    }
  };

  const searchItem = (query: any) => {
    if (!query) {
      setSearchData([]);
      setFocusedIndex(0);
      return;
    }
    const fuse = new Fuse(data, {
      keys: ["text"],
      minMatchCharLength: 2,
      threshold: 0.2,
    });
    const result = fuse.search(query);

    if (result.length) {
      setSearchData(result.map((item) => item.item));
    } else {
      setSearchData([]);
    }
  };
  return (
    <div className="quick-nav" style={{ ...styles }} onKeyDown={handleKeyDown}>
      <input
        type="text"
        onChange={(e) => searchItem(e.target.value)}
        placeholder="Search for a card"
        ref={(input) => input && input.focus()}
      />

      <div className="item-container">
        {searchData &&
          Array.isArray(searchData) &&
          searchData.slice(0, 10).map((item, idx) => (
            <div
              className={`search-result ${focusedIndex === idx ? "focus" : ""}`}
              key={idx}
              onClick={() => {
                onSelect(item.id);
              }}
            >
              {item.text}
            </div>
          ))}
      </div>
    </div>
  );
};
