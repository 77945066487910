import React, { useState } from "react";
import { styled } from "styled-components";
import { NavLink } from "react-router-dom";
import { LetsGoArrow } from "./Graphics/LetsGoArrow";
import { devices } from "../styling-config";

const StartContainer = styled.div`
  a {
    color: black;
    text-decoration: none;
    display: block;
  }
  &:hover {
    h2 {
      color: rgb(98, 211, 148);
      transition: 0.2s all;
    }
  }
  @media only screen and ${devices.xs} {
    margin-top: 0rem;
    margin-left: 0rem;
  }
  @media only screen and ${devices.lg} {
    margin-top: 2rem;
    margin-left: 8rem;
  }
`;

const Heading2 = styled.h2`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 0;
  font-size: 1.6rem;
  position: absolute;
  top: 0.4rem;
  z-index: 10;
`;

export const Start = () => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <StartContainer
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <NavLink to="/lists">
        <Heading2>Let’s do this!</Heading2>
        <div style={{ marginLeft: "5.8rem" }}>
          <LetsGoArrow
            width={180}
            name="lets-go-arrow"
            fillColor={isHovering ? "rgb(98,211,148)" : "black"}
          />
        </div>
      </NavLink>
    </StartContainer>
  );
};
