import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthAxios from "../hooks/useAuthAxios";
import useAuth from "../hooks/useAuth";

const tokenExchangeURL =
  "https://jr6pjsudef.execute-api.eu-central-1.amazonaws.com/test/api/auth/token/exchange/";

const useQuery = () => {
  const { search } = useLocation();
  return new URLSearchParams(search);
};

export const ProcessMagicLogin = () => {
  const navigate = useNavigate();
  let query = useQuery();
  const authAxios = useAuthAxios();
  const { setAuth } = useAuth();

  const magicToken = query.get("token");

  useEffect(() => {
    const getAccessToken = async (magicToken: string) => {
      const { data, status } = await authAxios.post(
        tokenExchangeURL,
        {},
        {
          headers: {
            Authorization: `Bearer ${magicToken}`,
          },
        }
      );
      if (status === 200 && data.accessToken) {
        const accessToken = data.accessToken;
        if (setAuth) {
          setAuth({ accessToken });
        }
        // need to fix this TS shit where it thinks setAuth could be null

        navigate(`/lists`);
      } else {
        // display error
      }
    };

    if (magicToken) {
      getAccessToken(magicToken).catch(console.error);
    }
  });

  return <div>Loading...</div>;
};
