import { ReactNode, createContext, useContext, useState } from "react";

import { MoveCard } from "../components/MoveCard/MoveCard";

interface ModalContextInterface {
  activeModal?: any;
  setModal?: any;
  exitModal?: any;
}

export const ModalContext = createContext<ModalContextInterface>({});

export function ModalProvider({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) {
  // define state
  // handle logic to show and hide modals
  // state machine?
  const [activeModal, setActiveModal] = useState<ReactNode>("");

  type cardProps = { type: "move-card"; cardId: string };
  type searchProps = { type: "search"; placeholder: string };

  const setModal = (props: cardProps | searchProps) => {
    if (props.type === "move-card") {
      setActiveModal(<MoveCard {...props} />);
    } else if (!props.type) {
      setActiveModal("");
    } else {
      setActiveModal("");
    }
  };

  const exitModal = () => {
    setActiveModal("");
  };

  // do we need to be able to register modals on init? Or just pass the modal we want to display in a slot,
  // as some kind of children?

  return (
    <ModalContext.Provider value={{ activeModal, setModal, exitModal }}>
      {activeModal}
      {children}
    </ModalContext.Provider>
  );
}

export function useModal() {
  return useContext(ModalContext);
}
