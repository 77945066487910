import { ReactEventHandler, useState } from "react";
import { styled } from "styled-components";
import useAuthAxios from "../hooks/useAuthAxios";

const magicRequestURL =
  "https://jr6pjsudef.execute-api.eu-central-1.amazonaws.com/test/api/auth/magic/";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
`;

const Heading1 = styled.h1`
  font-weight: normal;
`;

const StyledInput = styled.input`
  font-size: 1.2rem;
  border: 1px solid black;
  border-radius: 4px;
  padding: 0.4rem 0.7rem 0.4rem 0.7rem;
  outline-color: rgb(0, 255, 0, 1);
  width: 30vw;
  margin-right: 1rem;
`;

const SubmitButton = styled.input`
  font-size: 1.2rem;
  padding: 0.4rem;
  margin: 0;
`;

export const Login = () => {
  const [loginState, setLoginState] = useState("initial");
  const [userEmailAddress, setUserEmailAddress] = useState("");
  const authAxios = useAuthAxios();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoginState("submitted");

    // call API endpoint!
    const { status } = await authAxios.post(magicRequestURL, {
      emailAddress: userEmailAddress,
    });
    if (status !== 200) {
      alert("sorry, that’s no good");
    }
  };

  return (
    <Container>
      <Heading1>Login</Heading1>
      {loginState === "initial" && (
        <form onSubmit={handleSubmit}>
          <p>Nearly there! Let me send you a magic sign-in link.</p>
          <StyledInput
            type="text"
            name="email"
            placeholder="your@email.com"
            onChange={(e) => setUserEmailAddress(e.target.value)}
          />
          <SubmitButton type="submit" />
        </form>
      )}
      {loginState === "submitted" && (
        <div>
          <p>
            We’ve sent a magic sign in link to `{userEmailAddress}`.
            <br />
            See you in a bit!
          </p>
        </div>
      )}
    </Container>
  );
};
