import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import useAuthAxios from "../hooks/useAuthAxios";
import { styled } from "styled-components";

const ProfileLink = styled.div`
  a {
    color: black;
    text-decoration: none;
  }
`;

const User = styled.div`
  position: absolute;
  width: 200px;
  right: 1.5rem;
  top: 0.75rem;
  margin-right: 3rem;
  text-align: right;
`;

const userInfoURL =
  "https://jr6pjsudef.execute-api.eu-central-1.amazonaws.com/test/api/user/";

export const UserInfo = () => {
  const [userInfo, setUserInfo] = useState({ handle: "", emailAddress: "" });
  const authAxios = useAuthAxios();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const { data } = await authAxios.get(userInfoURL);
      if (data) {
        setUserInfo(data);
      }
      // set in component state? later on in shared store in case
      // other components may need the user’s name?
    };

    fetchUserInfo().catch(console.error);
  }, [authAxios]);

  return (
    <User>
      <ProfileLink>
        <NavLink to="/user">
          {`${userInfo?.handle || userInfo?.emailAddress}`}
        </NavLink>
      </ProfileLink>
    </User>
  );
};
