import React from "react";
import Logo from "../components/Logo";
import { styled } from "styled-components";
import Nav from "../components/Nav";
import { NavLink } from "react-router-dom";
import { ScrollToTop } from "../hooks/scrollToTop";

const Container = styled.div`
  margin: 3rem;
  width: 500px;
`;

const Intro = styled.div`
  margin-bottom: 3rem;
`;

const Description = styled.div`
  width: 75%;
`;

const Vote = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;

const Heading2 = styled.h2`
  font-weight: normal;
  margin: 0;
  margin-bottom: 2rem;
  line-height: 1;
`;

const Heading3 = styled.h2`
  font-weight: normal;
  font-size: 1.3rem;
  font-weight: 400;
  margin: 0;
  margin-bottom: 1rem;
  line-height: 1;
`;

const BackArrow = styled.div`
  position: fixed;
  top: 3rem;
  left: 2rem;
  font-weight: 300;
  font-size: 1.5rem;

  a {
    text-decoration: none;
    color: black;
  }
`;

export const Feature = ({
  className,
  title,
  image,
  description,
  votes,
  plannedDelivery,
}: {
  className?: string;
  title: string;
  image?: string;
  description: string;
  votes: number;
  plannedDelivery?: string;
}) => {
  return (
    <div className={className}>
      <div>
        <Heading3>{title}</Heading3>
      </div>
      <div>{image}</div>
      <Description>{description}</Description>
      {/* <div>Votes: {votes}</div> */}
      {/* {plannedDelivery ? <div>Planned delivery: {plannedDelivery}</div> : ""}
      <Vote>Vote &uarr; -- 21%</Vote> */}
    </div>
  );
};

const StyledFeature = styled(Feature)`
  position: relative;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 8px;
  padding: 1rem;
  padding-bottom: 3rem;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 4px 1px,
    rgba(15, 255, 15, 0.2) 4px 4px 4px;
  border: 1px solid rgb(220, 220, 220);
  background: white;
  font-size: 0.9rem;

  h3 {
    font-size: 1.3rem;
  }
`;

const FeatureList = styled.div`
  margin-bottom: 4rem;
`;

const committedFeatures = [
  {
    title: "Feature 1",
    image: "",
    description: "",
    votes: 0,
    plannedDelivery: "week xx",
  },
];

const completedFeatures = [
  {
    title: "Add card as child of another card by dropping it onto it",
    image: "",
    description:
      "Move a card to be a child of another card by dragging it onto the other card.",
    votes: 0,
  },
  {
    title: "Add icons to context menu",
    image: "",
    description: "Add icons to menu items in the card context menu",
    votes: 0,
  },
];

const featureBacklog = [
  {
    title: "Add feature voting mechanism for users",
    image: "",
    description: "Allow users to vote on features in the backlog.",
    votes: 0,
  },
  {
    title: "Compose a ‘working session’",
    image: "",
    description:
      "A feature that allows you to cherry pick cards from any list to compose a working session.",
    votes: 0,
  },
  {
    title: "Shared Lists",
    image: "",
    description:
      "Shared lists allow you to collaborate on a list and all of it’s descendants.",
    votes: 0,
  },
  {
    title: "Frecent Lists",
    image: "",
    description: "Display frequently and recently visited lists.",
    votes: 0,
  },
  {
    title: "Ready To Go List",
    image: "",
    description: "Display all tasks that have been labelled as ‘let’s go’",
    votes: 0,
  },
  {
    title: "Tree View",
    image: "",
    description:
      "Show the full hierarcy of lists and cards in a dedicated tree view.",
    votes: 0,
  },
  {
    title: "Move Card",
    image: "",
    description:
      "Move a card to another parent, from within the (...) context menu",
    votes: 0,
  },

  {
    title: "Add 5 min countdown timer to focused card",
    image: "",
    description:
      "Add small timer functionality to card in focus mode, that does a minute countdown.",
    votes: 0,
  },
  {
    title: "Add sound effect whenever a task is completed",
    image: "",
    description: "",
    votes: 0,
  },
  {
    title: "Add a way to show individual tasks that are completed",
    image: "",
    description:
      "Hovering over, or clicking on a task in the ‘done stack’ could sbow its name",
    votes: 0,
  },
];

export const PublicBacklog = () => {
  return (
    <Container>
      <ScrollToTop />
      <Logo />
      <BackArrow>
        <NavLink to="/lists">&larr;</NavLink>
      </BackArrow>
      <Intro>
        This is the public feature backlog for Chunki. You can vote on features
        you’d like to see, or submit a feature request of your own.
      </Intro>

      {/* <FeatureList>
        <Heading2>Committed Features</Heading2>
        {committedFeatures.map((feature) => (
          <StyledFeature {...feature} />
        ))}
      </FeatureList> */}

      <FeatureList>
        <Heading2>Feature Backlog</Heading2>
        {featureBacklog.map((feature) => (
          <StyledFeature {...feature} />
        ))}
      </FeatureList>

      <FeatureList>
        <Heading2>Completed Features</Heading2>
        {completedFeatures.map((feature) => (
          <StyledFeature {...feature} />
        ))}
      </FeatureList>
    </Container>
  );
};
