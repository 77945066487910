import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { CardsProvider } from "./context/CardsContext";
import { AuthProvider } from "./context/AuthProvider";
import { AnimationsProvider } from "./context/AnimationContext";
import { ModalProvider } from "./providers/ModalProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const ItemTypes = {
  CARD: "card",
};

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <DndProvider backend={HTML5Backend}>
      <AuthProvider>
        <CardsProvider>
          <ModalProvider>
            <AnimationsProvider>
              <App />
            </AnimationsProvider>
          </ModalProvider>
        </CardsProvider>
      </AuthProvider>
    </DndProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
