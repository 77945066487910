import axios from "axios";
import { config } from "../config";

const baseURL = config.baseURL;

export default axios.create({
  baseURL,
});

export const authAxios = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
