import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  ReactNode,
} from "react";

interface Auth {
  user?: {};
  accessToken?: string;
}

interface AuthContextInterface {
  auth: Auth | null;
  setAuth: Dispatch<SetStateAction<Auth>> | null;
}

const AuthContext = createContext<AuthContextInterface>({
  auth: null,
  setAuth: null,
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState({});

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
