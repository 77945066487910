import React from "react";
import { styled } from "styled-components";
import { TaskStack } from "./TaskStack";
import { useCards } from "../context/CardsContext";
import { useCardsDispatch } from "../context/CardsContext";
import { cardServiceAPI } from "../services/cardService";
import useAuthAxios from "../hooks/useAuthAxios";

const Container = styled.div`
  position: fixed;
  bottom: 10rem;
  right: 2rem;
  text-align: center;
  font-weight: 300;
  width: 120px;
`;

const Amount = styled.div`
  font-size: 1.2rem;
  margin-bottom: 0.7rem;
`;

const TimeSpan = styled.div`
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

const Archive = styled.div`
  font-size: 0.8rem;
  line-height: 1;
  margin-top: 2rem;
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  img {
    margin-top: 0.25rem;
    width: 14px;
  }
`;

export const TasksDone = () => {
  const authAxios = useAuthAxios();
  const { batchUpdateCards } = cardServiceAPI(authAxios);
  const dispatch = useCardsDispatch();
  const { cards } = useCards();

  const handleArchive = () => {
    dispatch && dispatch({ type: "ARCHIVE_ALL" });
    if (cards) {
      const ids = cards
        .filter((card) => card.status === "done")
        .map((card) => card.id);
      if (ids) {
        batchUpdateCards(ids, { status: "archived" });
      }
    }
  };

  const doneCards = cards.filter((card) => card.status === "done");
  const amount = doneCards.length;
  return (
    <Container>
      {amount > 0 && (
        <div>
          <Amount>{amount}</Amount>
          <div>
            <TaskStack amount={amount} />
          </div>
          <TimeSpan>{amount > 1 ? "tasks" : "task"} done</TimeSpan>

          <Archive onClick={() => handleArchive()}>
            archive all
            <br />
            <img src="/archive-icon.svg" alt="" />
          </Archive>
        </div>
      )}
    </Container>
  );
};
