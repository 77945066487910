import React from "react";
import { NavLink } from "react-router-dom";

export const Breadcrumb = ({
  card,
  parentCards,
}: {
  card: any;
  parentCards: any;
}) => {
  return (
    <ul className="breadcrumb">
      <li key={`000`}>
        <NavLink to={`/lists`}>Root</NavLink>
      </li>
      {parentCards &&
        parentCards.map((parent: any, idx: any) => (
          <li key={idx}>
            {" "}
            <NavLink to={`/lists/${parent.id}`}>{parent.text}</NavLink>
          </li>
        ))}
      <li>{card && card.text}</li>
    </ul>
  );
};
