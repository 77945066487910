import { styled } from "styled-components";
import { motion } from "framer-motion";
import { useLayoutEffect, useRef, useState } from "react";
import { useAnimations } from "../context/AnimationContext";

const BigCheckContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 55vh;
  }
`;

export const BigCheck = () => {
  const [bounce, setBounce] = useState(false);
  const { animations } = useAnimations();

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setBounce(true);
    setTimeout(() => {
      setBounce(false);
    }, 1500);
  }, [animations]);

  return (
    <BigCheckContainer>
      <motion.svg
        initial={false}
        transition={{
          type: "spring",
          stiffness: 600,
          damping: 25,
        }}
        animate={{
          scale: bounce ? "100%" : "50%",
          opacity: bounce ? 1 : 0.08,
        }}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 515.6 742.9"
        xmlSpace="preserve"
        className="big-check"
      >
        <style type="text/css">
          {`	.big-check .style0{opacity:0.9;}
	.big-check .style1{fill:#00FF8A;}
	.big-check .style2{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:1.4543;stroke-miterlimit:10;}`}
        </style>
        <g className="style0">
          <path
            className="style1"
            d="M458.4,5L458.4,5c44.9,16,68.2,65.4,52.2,110.2L306.9,685.6c-16,44.9-65.4,68.2-110.2,52.2l0,0
		c-44.9-16-68.2-65.4-52.2-110.2L348.1,57.3C364.2,12.4,413.5-11,458.4,5z"
          />
        </g>
        <line className="style2" x1="428.6" y1="87.1" x2="225.1" y2="656.8" />
        <g className="style0">
          <path
            className="style1"
            d="M48.8,291.5L48.8,291.5c42.9-20.7,94.5-2.6,115.1,40.3l138.5,287.5c20.7,42.9,2.6,94.5-40.3,115.1l0,0
		C219.2,755,167.7,737,147,694.1L8.6,406.6C-12.1,363.7,5.9,312.2,48.8,291.5z"
          />
        </g>
        <line className="style2" x1="86.3" y1="370.3" x2="224.5" y2="657.2" />
      </motion.svg>
    </BigCheckContainer>
  );
};
