import React from "react";
import "./CategorySelect.css";

export const CategorySelect = ({
  updateCategory,
  setVisibility,
}: {
  updateCategory: any;
  setVisibility: any;
}) => {
  const clickHandler = (category: string) => {
    updateCategory(category);
    setVisibility(false);
  };

  return (
    <div className="category-select">
      <ul>
        <li onClick={() => clickHandler("NO_WAY")} className="tag red">
          no way
        </li>
        <li
          onClick={() => clickHandler("DONT_THINK_SO")}
          className="tag salmon"
        >
          don’t think so
        </li>
        <li onClick={() => clickHandler("MAYBE")} className="tag buff">
          maybe
        </li>
        <li onClick={() => clickHandler("LETS_GO")} className="tag green">
          let’s go!
        </li>
      </ul>
    </div>
  );
};
