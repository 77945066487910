import React from "react";
import Check from "./CheckGraphic";
import "./CardApproval.css";

export const CardApproval = () => {
  return (
    <div className="card-approval">
      <Check />
    </div>
  );
};
