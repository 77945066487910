import React, { useState } from "react";
import { CategorySelect } from "./CategorySelect";
import "./Tags.css";
import { tagMap, tagMapping } from "./Card";

export const readinessColorMap: tagMap = {
  NO_WAY: "red",
  DONT_THINK_SO: "salmon",
  MAYBE: "buff",
  LETS_GO: "green",
};

export const Tags = ({
  readiness,
  updateReadiness,
}: {
  readiness?: string;
  updateReadiness: any;
}) => {
  const [tagSelectVisbile, setTagSelectVisible] = useState(false);

  const updateCategory = (category: string) => {
    updateReadiness(category);
  };

  return (
    <div>
      <div className="tags">
        {readiness && (
          <div
            onClick={() => setTagSelectVisible(!tagSelectVisbile)}
            className={`tag ${readinessColorMap[readiness]}`}
          >
            {tagMapping[readiness as keyof tagMap]}
          </div>
        )}
        {!readiness && (
          <div
            onClick={() => setTagSelectVisible(!tagSelectVisbile)}
            className="tag empty"
          ></div>
        )}
      </div>
      {tagSelectVisbile && (
        <CategorySelect
          updateCategory={updateCategory}
          setVisibility={setTagSelectVisible}
        />
      )}
    </div>
  );
};
