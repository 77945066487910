import { useContext } from "react";
import { styled } from "styled-components";
import { CardSearch } from "../CardSearch";
import { CardsContext, useCardsDispatch } from "../../context/CardsContext";
import { cardServiceAPI } from "../../services/cardService";
import useAuthAxios from "../../hooks/useAuthAxios";
import { ModalContext } from "../../providers/ModalProvider";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`;

const Container = styled.div`
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 1rem;
`;

export const MoveCard = ({ cardId }: { cardId: string }) => {
  const cards = useContext(CardsContext);
  const { exitModal } = useContext(ModalContext);
  const dispatch = useCardsDispatch();
  const authAxios = useAuthAxios();
  const { updateCard } = cardServiceAPI(authAxios);

  const moveCard = (cardId: string) => async (newParentId: string) => {
    dispatch &&
      dispatch({
        type: "UPDATE_CARD",
        id: cardId,
        data: { cardId, parentId: newParentId },
      });
    exitModal();
    await updateCard({ id: cardId, parentId: newParentId });
  };

  return (
    <Wrapper onClick={exitModal}>
      <Container
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        Move card to...
        <br />
        <br />
        <CardSearch
          data={cards.cards}
          onSelect={moveCard(cardId)}
          styles={{ border: "0", padding: "0" }}
        />
      </Container>
    </Wrapper>
  );
};
