import React from "react";

export const LandingPageBackground = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2077.9 3100.3"
      xmlSpace="preserve"
      width="1400"
    >
      <style type="text/css">
        {`
        @media only screen and (max-width: 1024px) {
            .arrow {
                display: none;
            }
        }

        .st0{fill:#F2F2F4;}
        .st1{fill:none;stroke:rgb(255,0,0);stroke-width:27;stroke-miterlimit:10;}
        .st2{fill:none;stroke:#DB3F31;stroke-width:27;stroke-miterlimit:10;}
        .st3{clip-path:url(#SVGID_00000133503910519207018320000005519103396596449162_);fill:none;stroke:#09D68C;stroke-width:2;stroke-miterlimit:10;}
        .st4{clip-path:url(#SVGID_00000075131465444680899550000004795587674063852735_);fill:none;stroke:#09D68C;stroke-width:2;stroke-miterlimit:10;}
        .st5{clip-path:url(#SVGID_00000010997011916260626320000001985759368052576439_);fill:none;stroke:#09D68C;stroke-width:2;stroke-miterlimit:10;}
        .st6{clip-path:url(#SVGID_00000031893879447699861630000003932692760519798453_);fill:none;stroke:#09D68C;stroke-width:2;stroke-miterlimit:10;}
        .st7{clip-path:url(#SVGID_00000023959444509123037360000013263034324672866433_);fill:none;stroke:#09D68C;stroke-width:2;stroke-miterlimit:10;}
        .st8{clip-path:url(#SVGID_00000018228525107544807150000012073105637854424711_);fill:none;stroke:#09D68C;stroke-width:2;stroke-miterlimit:10;}
        .st9{display:none;}
        .st10{display:inline;}`}
      </style>

      <g className="arrow">
        <rect
          x="598.7"
          y="698.4"
          className="st0"
          width="237.9"
          height="239.9"
        />
        <path
          d="M663,762.7L663,762.7L663,762.7l1.6,10l0,0l0,0l2.5,10l0,0l0,0l3.4,10.3l0,0l0,0l4.5,10l0,0l0,0l5.2,9.8l0,0l0,0l5.9,9.6
	l0,0l0,0l6.6,8.8l0,0l0,0l7.1,8.1l0,0l0,0l7.5,7.3l0,0l0,0l7.8,6.3l0,0l0,0l7.5,4.9V872v0.1v0.1c0,0,0,0.1,0.1,0.1
	c0,0,0,0.1,0.1,0.1s0.1,0.1,0.1,0.1l0.1,0.1h0.1h0.1l48.3,6.9h0.1l0,0l0,0l0,0c0.1,0,0.2,0,0.3-0.1h0.1l0.1-0.1c0,0,0-0.1,0.1-0.1
	l0.1-0.1V879v-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1V865l0,0v-0.1v-0.1c0,0,0,0,0-0.1l0,0l-12-28l0,0l0,0c0,0,0,0-0.1-0.1l-0.1-0.1
	l-0.1-0.1c0,0-0.1,0-0.1-0.1h-0.1h-0.1h-0.1h-0.1l0,0l0,0l-11.8,6.8l-11.6-6.8l-6.3-4.1l-6.2-5l-5.9-5.8l-5.6-6.4l-5.4-7.1l-4.6-7.6
	l-4.2-7.9l-3.4-7.9l-2.9-8.1l-2-8l-1.2-7.9l-0.5-7.5l0,0c0,0,0,0,0-0.1v-0.1v-0.1l0,0l0,0c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1
	c0,0,0,0-0.1,0l0,0l-5.9-3.6l0,0l0,0l-6.1-3.4h-0.1h-0.1h-0.1h-0.1h-0.1H675l-6.1,3.4l0,0l0,0l-6.1,3.6l0,0c0,0-0.1,0-0.1,0.1
	c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1v0.1v0.1v0.1c0,0,0,0,0,0.1L663,762.7z M669.6,750.3
	C669.6,750.3,669.7,750.3,669.6,750.3l5.7-3.2l5.7,3.2l0,0l4.9,2.9L681,756l0,0l-5.7,3.2l-5.7-3.2l0,0l-5-2.8L669.6,750.3z
	 M716.3,852.2l-7.7-6.3l-7.5-7.2l-7.1-8l-6.6-8.8l-5.9-9.6l-5.1-9.7l-4.5-10l-3.4-10.2l-2.5-10l-1.6-9.8l-0.5-8l4.9,2.8l0,0l5.7,3.2
	l0.4,7.1l0,0l0,0l1.2,7.9l0,0l0,0l2,8.1l0,0l0,0l2.9,8.2l0,0l0,0l3.4,8.2l0,0l0,0l4.2,7.9l0,0l0,0l4.8,7.5l0,0l0,0l5.3,7.1l0,0l0,0
	l5.7,6.5l0,0l0,0l5.9,5.8l0,0l0,0l6.2,5l0,0l0,0l6.3,4.1l0,0l0,0l11.1,6.4l-10.7,6.1L716.3,852.2z M724.4,858.8l46.9,6.8V878
	l-46.9-6.7V858.8z"
        />
        <polyline className="st1" points="407,862.4 454.5,928.4 577.8,954.2 " />
      </g>
      <g className="arrow">
        <rect
          x="1279.2"
          y="1417.5"
          transform="matrix(0.7255 -0.6883 0.6883 0.7255 -673.9749 1383.6509)"
          className="st0"
          width="236.4"
          height="238.3"
        />
        <path
          d="M1418.2,1462.9l-5.6,5.3l-5.6,5.3l-0.1,0.1c0,0,0,0,0,0.1s0,0.1-0.1,0.2v0.1c0,0.1,0,0.1,0,0.2v0.1c0,0,0,0.1,0.1,0.1
	c0,0,0,0.1,0.1,0.1l3.6,4.4l2.6,5.1l1.9,5.6l0.8,6.1v6.4l-1.1,6.8l-1.6,6.8l-2.8,6.9l-3.4,6.8l-4.3,6.6l-4.9,6.1l-5.6,5.9
	l-10.7,10.1l-7-7.4l0,0l0,0l-0.1-0.1l-0.1-0.1l0,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.2,0.1-0.2,0.2l0,0l0,0
	c-0.1,0.1-0.1,0.1-0.1,0.2l0,0l0,0l-17.1,38.6l0,0c0,0.1,0,0.1,0,0.2v0.1l0,0v0.1v0.1l0,0l0,0l0,0c0,0.1,0.1,0.2,0.1,0.2l0,0
	l7.7,8.1l0,0c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.1,0,0.2,0h0.1c0.1,0,0.2,0,0.3,0l0,0l39.5-15l0,0l0,0c0.1,0,0.2-0.1,0.2-0.2
	c0.1-0.1,0.1-0.2,0.2-0.3v-0.1c0-0.1,0-0.3,0-0.4l0,0c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1l0,0l0,0l-7-7.4l10.7-10.2l0,0l0,0l7-7.3
	l0,0l0,0l6.3-7.8l0,0l0,0l5.3-8.3l0,0l0,0l4.4-8.5l0,0l0,0l3.5-8.7c0,0,0,0,0-0.1l0,0l2.3-8.7l0,0l0,0l1.2-8.5c0,0,0,0,0-0.1l0,0
	l-0.1-8.3c0,0,0,0,0-0.1l0,0l-1.3-7.6c0,0,0,0,0-0.1l0,0l-2.2-7.3v-0.1l0,0l-3.4-6.4c0,0,0,0,0-0.1c0,0,0,0,0-0.1l-4.6-5.6l0,0l0,0
	l-3.8-4l-3.8-4C1419,1462.7,1418.5,1462.7,1418.2,1462.9z M1433.6,1483.7l2.2,7.2l1.3,7.5l0.1,8.1l-1.1,8.4l-2.2,8.6l-3.5,8.7
	l-4.4,8.4l-5.3,8.2l-6.3,7.8l-6.9,7.3l-10.7,10.1l-6.6-6.9l10.7-10.2l0,0l0,0l5.7-5.8l0,0c0,0,0,0,0.1-0.1l4.9-6.3l0,0l0,0l4.4-6.7
	l0,0l0,0l3.4-6.9l0,0l0,0l2.8-7c0,0,0,0,0-0.1l0,0l1.8-6.7c0,0,0,0,0-0.1l0,0l1-6.9c0,0,0,0,0-0.1s0,0,0-0.1v-6.5c0,0,0,0,0-0.1l0,0
	l-0.9-6.3v-0.1l0,0l-1.9-5.6c0,0,0,0,0-0.1l0,0l-2.6-5.3c0,0,0,0,0-0.1v-0.1l-3.3-3.9l5.1-4.9l5.1-4.8l4,4.9L1433.6,1483.7z
	 M1413.6,1469.3l5.1-4.8l3.3,3.5l3.3,3.5l-5.1,4.8l-5.1,4.8l-3.3-3.5l-3.3-3.5L1413.6,1469.3z M1358.3,1590.3l38.2-14.3l6.4,6.8
	l-38,14.5L1358.3,1590.3z"
        />
        <polyline
          className="st1"
          points="1626.6,1352.4 1546,1561.1 1367.9,1756.8 "
        />
      </g>

      <g className="arrow">
        <rect
          x="564.3"
          y="2228.7"
          transform="matrix(0.6883 -0.7255 0.7255 0.6883 -1489.5179 1227.4547)"
          className="st0"
          width="238.3"
          height="236.4"
        />
        <path
          d="M661.5,2273.3l-3.8,4l-3.8,4l0,0l0,0l-4.6,5.6c0,0,0,0,0,0.1c0,0,0,0,0,0.1l-3.4,6.4l0,0v0.1l-2.2,7.3l0,0c0,0,0,0,0,0.1
		l-1.3,7.6l0,0c0,0,0,0,0,0.1l-0.1,8.3l0,0c0,0,0,0,0,0.1l1.2,8.5l0,0l0,0l2.3,8.7l0,0c0,0,0,0,0,0.1l3.5,8.7l0,0l0,0l4.4,8.5l0,0
		l0,0l5.3,8.3l0,0l0,0l6.3,7.8l0,0l0,0l7,7.3l0,0l0,0l10.7,10.2l-7,7.4l0,0l0,0l-0.1,0.1c0,0,0,0.1-0.1,0.1l0,0c0,0.1,0,0.2,0,0.4
		v0.1c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.1,0.2,0.2l0,0l0,0l39.5,15l0,0c0.1,0,0.2,0.1,0.3,0h0.1c0.1,0,0.1,0,0.2,0
		c0.1,0,0.2-0.1,0.2-0.2l0,0l7.7-8.1l0,0c0.1-0.1,0.1-0.1,0.1-0.2l0,0l0,0l0,0v-0.1v-0.1l0,0v-0.1c0-0.1,0-0.1,0-0.2l0,0l-17.1-38.6
		l0,0l0,0c0-0.1-0.1-0.1-0.1-0.2l0,0l0,0c-0.1-0.1-0.2-0.1-0.2-0.2h-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0.1l0,0l-0.1,0.1
		c0,0-0.1,0-0.1,0.1l0,0l0,0l-7,7.4l-10.7-10.1l-5.6-5.9l-4.9-6.1l-4.3-6.6l-3.4-6.8l-2.8-6.9l-1.6-6.8l-1.1-6.8v-6.4l0.8-6.1
		l1.9-5.6l2.6-5.1l3.6-4.4c0,0,0-0.1,0.1-0.1l0.1-0.1v-0.1c0-0.1,0-0.1,0-0.2v-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0-0.1l-0.1-0.1
		l-5.6-5.3l-5.6-5.3C662.3,2273,661.8,2273,661.5,2273.3z M650.5,2287.8l4-4.9l5.1,4.8l5.1,4.9l-3.3,3.9v0.1c0,0,0,0,0,0.1l-2.6,5.3
		l0,0c0,0,0,0,0,0.1l-1.9,5.6l0,0c0,0,0,0,0,0.1l-0.9,6.3l0,0c0,0,0,0,0,0.1v6.5c0,0,0,0,0,0.1c0,0,0,0,0,0.1l1,6.9l0,0
		c0,0,0,0,0,0.1l1.8,6.7l0,0c0,0,0,0,0,0.1l2.8,7l0,0l0,0l3.4,6.9l0,0l0,0l4.4,6.7l0,0l0,0l4.9,6.3c0,0,0,0,0.1,0.1l0,0l5.7,5.8l0,0
		l0,0l10.7,10.2l-6.6,6.9l-10.7-10.1l-6.9-7.3l-6.3-7.8l-5.3-8.2l-4.4-8.4l-3.5-8.7l-2.2-8.6l-1.1-8.4l0.1-8.1l1.3-7.5l2.2-7.2
		L650.5,2287.8z M672.3,2284.5l-3.3,3.5l-3.3,3.5l-5.1-4.8l-5.1-4.8l3.3-3.5l3.3-3.5l5.1,4.8L672.3,2284.5z M715.9,2407.6l-38-14.5
		l6.4-6.8l38.2,14.3L715.9,2407.6z"
        />
        <polyline
          className="st1"
          points="656.4,2152.1 617.4,2201.8 552.4,2336.5 532.9,2464.2 "
        />
      </g>

      <g id="Layer_5"></g>
      <g id="Layer_4" className="outer-lines">
        <g>
          <g>
            <defs>
              <rect
                id="SVGID_1_"
                x="-303.7"
                y="0.5"
                width="2617.1"
                height="3799.8"
              />
            </defs>
            <clipPath id="SVGID_00000033366914249738123720000003133777009079025854_">
              <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
            </clipPath>

            <path
              style={{
                clipPath:
                  "url(#SVGID_00000033366914249738123720000003133777009079025854_)",
                fill: "none",
                stroke: "#09D68C",
                strokeWidth: "2",
                strokeMiterlimit: "10",
              }}
              d="
				M1698.1,3398.4c57.9-100.2,91-216.5,91-340.5c0-257.9-143.2-482.4-354.4-598.2c33.6-69.1,55.8-144.8,64.3-224.6
				c295.5-74.7,514.1-342.4,514.1-661.1c0-211.2-96-399.9-246.8-525c2-21,3-42.4,3-63.9c0-289.8-180.8-537.4-435.8-636.1
				c-23-59.5-54.2-115-92.1-165c86.5-114.3,137.9-256.7,137.9-411.1c0-376.6-305.3-681.9-681.9-681.9S15.6-603.7,15.6-227.1
				C15.6-72.7,66.9,69.7,153.5,184C66.9,298.3,15.6,440.8,15.6,595.2c0,289.8,180.8,537.4,435.8,636.1
				c41.1,106.1,108,199.4,193,272.2c-291,77.8-505.3,343.3-505.3,658.8c0,257.9,143.2,482.4,354.4,598.2
				c-43.7,89.9-68.2,190.8-68.2,297.5c0,132.9,38,256.8,103.7,361.7c-185.9,164.9-303.1,405.6-303.1,673.6
				c0,497.1,402.9,900,900,900s900-402.9,900-900C2025.9,3813.4,1898.3,3563.5,1698.1,3398.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect
                id="SVGID_00000145738161169843299920000009152486688244306593_"
                x="-294.7"
                y="0.5"
                width="2617.1"
                height="3799.8"
              />
            </defs>
            <clipPath id="SVGID_00000054225402224689621110000009555757271392999060_">
              <use
                xlinkHref="#SVGID_00000145738161169843299920000009152486688244306593_"
                style={{ overflow: "visible" }}
              />
            </clipPath>

            <path
              style={{
                clipPath:
                  "url(#SVGID_00000054225402224689621110000009555757271392999060_)",
                fill: "none",
                stroke: "#09D68C",
                strokeWidth: "2",
                strokeMiterlimit: "10",
              }}
              d="
				M1707.1,3398.4c57.9-100.2,91-216.5,91-340.5c0-257.9-143.2-482.4-354.4-598.2c33.6-69.1,55.8-144.8,64.3-224.6
				c295.5-74.7,514.1-342.4,514.1-661.1c0-211.2-96-399.9-246.8-525c2-21,3-42.4,3-63.9c0-289.8-180.8-537.4-435.8-636.1
				c-23-59.5-54.2-115-92.1-165c86.5-114.3,137.9-256.7,137.9-411.1c0-376.6-305.3-681.9-681.9-681.9S24.6-603.7,24.6-227.1
				C24.6-72.7,76,69.7,162.5,184C76,298.3,24.6,440.8,24.6,595.2c0,289.8,180.8,537.4,435.8,636.1c41.1,106.1,108,199.4,193,272.2
				c-291,77.8-505.3,343.3-505.3,658.8c0,257.9,143.2,482.4,354.4,598.2c-43.7,89.9-68.2,190.8-68.2,297.5
				c0,132.9,38,256.8,103.7,361.7c-185.8,164.8-303,405.4-303,673.5c0,497.1,402.9,900,900,900s900-402.9,900-900
				C2035,3813.4,1907.3,3563.5,1707.1,3398.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect
                id="SVGID_00000035522642973460633790000004310561379043543479_"
                x="-285.7"
                y="0.5"
                width="2617.1"
                height="3799.8"
              />
            </defs>
            <clipPath id="SVGID_00000075868480677281483230000007499715174647557546_">
              <use
                xlinkHref="#SVGID_00000035522642973460633790000004310561379043543479_"
                style={{ overflow: "visible" }}
              />
            </clipPath>

            <path
              style={{
                clipPath:
                  "url(#SVGID_00000075868480677281483230000007499715174647557546_)",
                fill: "none",
                stroke: "#09D68C",
                strokeWidth: "2",
                strokeMiterlimit: "10",
              }}
              d="
				M1716.2,3398.4c57.9-100.2,91-216.5,91-340.5c0-257.9-143.2-482.4-354.4-598.2c33.6-69.1,55.8-144.8,64.3-224.6
				c295.5-74.7,514.1-342.4,514.1-661.1c0-211.2-96-399.9-246.8-525c2-21,3-42.4,3-63.9c0-289.8-180.8-537.4-435.8-636.1
				c-23-59.5-54.2-115-92.1-165c86.5-114.3,137.9-256.7,137.9-411.1c0-376.6-305.3-681.9-681.9-681.9S33.7-603.7,33.7-227.1
				C33.7-72.7,85,69.7,171.5,184C85,298.3,33.7,440.8,33.7,595.2c0,289.8,180.8,537.4,435.8,636.1c41.1,106.1,108,199.4,193,272.2
				c-291,77.8-505.3,343.3-505.3,658.8c0,257.9,143.2,482.4,354.4,598.2c-43.7,89.9-68.2,190.8-68.2,297.5
				c0,132.9,38,256.8,103.7,361.7C361.2,3584.5,244,3825.1,244,4093.2c0,497.1,402.9,900,900,900s900-402.9,900-900
				C2044,3813.4,1916.4,3563.5,1716.2,3398.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect
                id="SVGID_00000017499112235287456100000009493825461360509833_"
                x="-276.6"
                y="0.5"
                width="2617.1"
                height="3799.8"
              />
            </defs>
            <clipPath id="SVGID_00000060715394362567517950000009509863179393678998_">
              <use
                xlinkHref="#SVGID_00000017499112235287456100000009493825461360509833_"
                style={{ overflow: "visible" }}
              />
            </clipPath>

            <path
              style={{
                clipPath:
                  "url(#SVGID_00000060715394362567517950000009509863179393678998_)",
                fill: "none",
                stroke: "#09D68C",
                strokeWidth: "2",
                strokeMiterlimit: "10",
              }}
              d="
				M1725.2,3398.4c57.9-100.2,91-216.5,91-340.5c0-257.9-143.2-482.4-354.4-598.2c33.6-69.1,55.8-144.8,64.3-224.6
				c295.5-74.7,514.1-342.4,514.1-661.1c0-211.2-96-399.9-246.8-525c2-21,3-42.4,3-63.9c0-289.8-180.8-537.4-435.8-636.1
				c-23-59.5-54.2-115-92.1-165c86.5-114.3,137.9-256.7,137.9-411.1c0-376.6-305.3-681.9-681.9-681.9S42.7-603.7,42.7-227.1
				C42.7-72.7,94,69.7,180.6,184C94,298.3,42.7,440.8,42.7,595.2c0,289.8,180.8,537.4,435.8,636.1c41.1,106.1,108,199.4,193,272.2
				c-291,77.8-505.3,343.3-505.3,658.8c0,257.9,143.2,482.4,354.4,598.2c-43.7,89.9-68.2,190.8-68.2,297.5
				c0,132.9,38,256.8,103.7,361.7C370.2,3584.5,253,3825.1,253,4093.2c0,497.1,402.9,900,900,900s900-402.9,900-900
				C2053,3813.4,1925.4,3563.5,1725.2,3398.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect
                id="SVGID_00000058548143495015422960000015123974581563016877_"
                x="-267.6"
                y="0.5"
                width="2617.1"
                height="3799.8"
              />
            </defs>
            <clipPath id="SVGID_00000176016973835347163600000017246834542112229011_">
              <use
                xlinkHref="#SVGID_00000058548143495015422960000015123974581563016877_"
                style={{ overflow: "visible" }}
              />
            </clipPath>

            <path
              style={{
                clipPath:
                  "url(#SVGID_00000176016973835347163600000017246834542112229011_)",
                fill: "none",
                stroke: "#09D68C",
                strokeWidth: "2",
                strokeMiterlimit: "10",
              }}
              d="
				M1734.2,3398.4c57.9-100.2,91-216.5,91-340.5c0-257.9-143.2-482.4-354.4-598.2c33.6-69.1,55.8-144.8,64.3-224.6
				c295.5-74.7,514.1-342.4,514.1-661.1c0-211.2-96-399.9-246.8-525c2-21,3-42.4,3-63.9c0-289.8-180.8-537.4-435.8-636.1
				c-23-59.5-54.2-115-92.1-165c86.5-114.3,137.9-256.7,137.9-411.1c0-376.6-305.3-681.9-681.9-681.9S51.7-603.7,51.7-227.1
				C51.7-72.7,103,69.7,189.6,184C103.1,298.3,51.7,440.8,51.7,595.2c0,289.8,180.8,537.4,435.8,636.1
				c41.1,106.1,108,199.4,193,272.2c-291,77.8-505.3,343.3-505.3,658.8c0,257.9,143.2,482.4,354.4,598.2
				c-43.7,89.9-68.2,190.8-68.2,297.5c0,132.9,38,256.8,103.7,361.7C379.2,3584.6,262,3825.3,262,4093.3c0,497.1,402.9,900,900,900
				s900-402.9,900-900C2062.1,3813.4,1934.4,3563.5,1734.2,3398.4z"
            />
          </g>
        </g>
        <g>
          <g>
            <defs>
              <rect
                id="SVGID_00000113345638895747424400000015189393051454406539_"
                x="-258.6"
                y="0.5"
                width="2617.1"
                height="3799.8"
              />
            </defs>
            <clipPath id="SVGID_00000127023468875062139360000003396449307967650462_">
              <use
                xlinkHref="#SVGID_00000113345638895747424400000015189393051454406539_"
                style={{ overflow: "visible" }}
              />
            </clipPath>

            <path
              style={{
                clipPath:
                  "url(#SVGID_00000127023468875062139360000003396449307967650462_)",
                fill: "none",
                stroke: "#09D68C",
                strokeWidth: "2",
                strokeMiterlimit: "10",
              }}
              d="
				M1743.3,3398.4c57.9-100.2,91-216.5,91-340.5c0-257.9-143.2-482.4-354.4-598.2c33.6-69.1,55.8-144.8,64.3-224.6
				c295.5-74.7,514.1-342.4,514.1-661.1c0-211.2-96-399.9-246.8-525c2-21,3-42.4,3-63.9c0-289.8-180.8-537.4-435.8-636.1
				c-23-59.5-54.2-115-92.1-165c86.5-114.3,137.9-256.7,137.9-411.1c0-376.6-305.3-681.9-681.9-681.9S60.8-603.7,60.8-227.1
				c0,154.4,51.3,296.8,137.9,411.1C112.1,298.3,60.8,440.8,60.8,595.2c0,289.8,180.8,537.4,435.8,636.1
				c41.1,106.1,108,199.4,193,272.2c-291,77.8-505.3,343.3-505.3,658.8c0,257.9,143.2,482.4,354.4,598.2
				c-43.7,89.9-68.2,190.8-68.2,297.5c0,132.9,38,256.8,103.7,361.7c-185.9,164.9-303.1,405.6-303.1,673.6
				c0,497.1,402.9,900,900,900s900-402.9,900-900C2071.1,3813.4,1943.5,3563.5,1743.3,3398.4z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
