import { useRef, useState } from "react";
import "./NewCard.css";
import { useCardsDispatch } from "../context/CardsContext";
import { v4 } from "uuid";
import { cardServiceAPI, getNextOrderNumber } from "../services/cardService";
import useAuthAxios from "../hooks/useAuthAxios";
import { useCards } from "../context/CardsContext";
import { styled } from "styled-components";

const AddCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  text-align: center;
  margin-bottom: 0.5rem;
  padding: 0.4rem 0.6rem 0.4rem 0.6rem;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 20px;
  color: black;
  font-size: 0.8rem;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.75);
  background: white;
  &:hover {
    background: rgba(0, 255, 0, 0.35);
    transition: background 0.2s;
  }
`;

const NewCard = ({ parentCardId }: { parentCardId?: any }) => {
  const [isOpen, setOpen] = useState(false);
  const [text, setText] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useCardsDispatch();
  const { cards } = useCards();
  const authAxios = useAuthAxios();
  const { addCard } = cardServiceAPI(authAxios);

  const submitCard = async ({
    id,
    parentId,
    text,
    orderNumber,
    status,
  }: {
    id: string;
    parentId: string;
    text: string;
    orderNumber: number;
    status: string;
  }) => {
    await addCard({
      id,
      text,
      parentId,
      status,
      orderNumber,
    });
  };

  const handleBlur = () => {
    // setText("");
    // setOpen(false);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Escape") {
      setText("");
      setOpen(false);
    }
    if (e.key === "Enter" && !e.shiftKey) {
      const parentId = parentCardId || "0";
      const orderNumber = getNextOrderNumber(cards, parentId);
      const newCard = { id: v4(), parentId, orderNumber, text, status: "open" };
      console.log({ newCard });

      if (dispatch) {
        dispatch({
          type: "ADD_CARD",
          ...newCard,
        });

        // add to server
        submitCard(newCard);
      }
      //
      setText("");
      // e.target.blur();
      // setOpen(false);
    }
  };

  return (
    <AddCard className="add-card">
      {!isOpen && (
        <Label
          onClick={() => {
            setOpen(!isOpen);
            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }, 0);
          }}
        >
          add card
        </Label>
      )}

      <div className={`card-input ${isOpen ? "open" : ""}`}>
        <input
          type="text"
          value={text}
          onBlur={handleBlur}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e)}
          ref={inputRef}
          maxLength={100}
        />
        {/* <div onClick={() => submitCard()}>submit</div> */}
      </div>
    </AddCard>
  );
};

export default NewCard;
